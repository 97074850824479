import React, { useState } from 'react';
import useT from '../Traduction'
import { Auth } from 'aws-amplify';


function FormatSupplierName({ userGroup, dataProject }) {
  const t = useT();
  const [errorMessage, setErrorMessage] = useState('')
  const [inProgress, setInProgress] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const handleSubmitForm = async (event) => {
    setSuccessMessage("")
    event.preventDefault();
    const supplier = document.getElementById('supplier').value.toUpperCase();

    if (userGroup === 'super-admin' || userGroup === 'admin') {
      setErrorMessage('')
      if (supplier === '') {
        setErrorMessage(t('Please_enter_a_supplier') + '.');
        return;
      } else {
        setInProgress(t('Get_supplier_in_progress'))
        FetchApiAddSupplier(supplier, setErrorMessage, t, dataProject, setInProgress, setSuccessMessage);
      }
    } else {
      setErrorMessage(t('Required_rights_to_perform_this_action'));
    }
  };

  return (
     <div className='container-fluid mt-5 d-flex justify-content-center'>
      <div className='boxed'>
          <form onSubmit={handleSubmitForm} className='form-control text-center shadow p-3 mb-5 bg-body rounded form-style'>
              <div className='row gx-5'>
                  <div className='col-md-12 my-3'>
                      <label htmlFor='supplier' className='mb-3 fw-bold'>{t('Supplier')} :</label>
                      <input type='text' name='supplier' id='supplier' placeholder={t('Supplier')} className='form-control mt-3'/>
                      {errorMessage && (
                          <div className='alert alert-warning mt-4 fw-bold'>{errorMessage}</div>
                      )}
                      {inProgress && (
                        <div className='alert alert-primary mt-4 fw-bold'>{inProgress}</div>
                      )}
                      {successMessage && (
                        <div className='alert alert-success mt-4 fw-bold'>{successMessage}</div>
                      )}
                  </div>
              </div>
              <button type='submit' className='btn  mb-3'>{t('Validate')}</button>
          </form>
      </div>
  </div>
  );
}

async function FetchApiAddSupplier(supplier, setErrorMessage, t, dataProject, setInProgress, setSuccessMessage) {
  let url = `https://api.datacatalog.${dataProject.environment}.gestimag.com/create-supplier`;
  const response = await fetch(url, {
    headers: {
      'Authorization': `BearerCognito ${dataProject.cognitoToken}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify({'supplier': supplier}),
  })
  let dataResultSupplier;
  try {
    const data = await response.json();
    dataResultSupplier = data.result?.message;
  } catch (error) {
    setErrorMessage(t('Unexpected_error'));
  }

  setInProgress('')
  if (!response.ok) {
    if (response.status === 0) {
        setErrorMessage(`${t('Download_failed_due_to_a_connection_problem')}.`);
    } else if (response.status === 500) {
        setErrorMessage(t('Unexpected_error'));
    } else if (response.status === 401) {
        setErrorMessage(t('Unauthorized'));
        console.log(t('Unauthorized'));
        Auth.signOut()
    } else if (response.status === 400) {
      if (dataResultSupplier == `Error item ${supplier} already exist`) {
        setErrorMessage(t('Supplier_already_exist'));
      } else {
        setErrorMessage(t('Unexpected_error'));
      }
    } else {
        setErrorMessage(t('Unexpected_error'));
    }
  } else {
    setSuccessMessage(t('Successful creation'))
  }
}   

export default FormatSupplierName;
