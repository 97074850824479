import React from "react";
import useT  from '../../../Traduction';

const ValueOverview = ({ formatData, nameAlreadyExist, setFormatName, formatName }) => {
  const t = useT();
  const handleInputChangeFormatName = (event) => {
    setFormatName(event.target.value.toUpperCase());
  };
  return (
    <div>
      <h4 className="text-center mb-5">{t("General_information")}</h4>
      <div className="row">
        {Object.entries(formatData.format).map(([key, value], index) => (
          <div className="col-md-4 mb-2" key={index}>
            {key === "name" && (
              <>
                {!nameAlreadyExist ? (
                  <div>
                    <strong>{t("Format")}: </strong>{formatName.toUpperCase() + "_" + formatData.format.supplier}
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <strong>{t("format_name_format")}: </strong>
                    <input
                      className="form-control"
                      type="text"
                      placeholder={t("Format_name")}
                      value={formatName}
                      onChange={handleInputChangeFormatName}
                    />
                  </div>
                )}
              </>
            )}
            {key === "file_type" && (
              <div>
                <strong>{t("File_type")}:</strong> {value.toUpperCase()}
              </div>
            )}
            {key === "has_headers" && (
              <div>
                <strong>{t("Header")}:</strong> {typeof value === "boolean" ? (value ? t("Yes") : t("No")) : value}
              </div>
            )}

            {key === "fixed_field_size" && (
              <div>
                <strong>{t("Fixed_field_size")}:</strong> {typeof value === "boolean" ? (value ? t("Yes") : t("No")) : value}
              </div>
            )}

            {key === "encoding" && (
              <div>
                <strong>{t("Encoding")}:</strong> {value}
              </div>
            )}

            {formatData.format.file_type === "csv" && key === "quoting_char" && (
              <div>
                <strong>{t("Quoting_char")}:</strong> {value}
              </div>
            )}

            
            {formatData.format.file_type === "csv" && key === "separator" && (
              <div>
                <strong>{t("Separator")}:</strong> {value}
              </div>
            )}

            {key === "supplier" && (
              <div>
                <strong>{t("Supplier")}:</strong> {value}
              </div>
            )}

            {formatData.format.fixed_field_size && key === "unit_price" && (
              <div>
                <strong>{t("Unit_price")}:</strong> {t(value)}
              </div>
            )}

            {key === "country" && (
              <div>
                <strong>{t("Country")}:</strong> {value}
              </div>
            )}

            {key === "language" && (
              <div>
                <strong>{t("Language")}:</strong> {value}
              </div>
            )}

            {key === "skip_rows" && (
              <div>
                <strong>{t("Skip_rows")}:</strong> {value}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ValueOverview;
