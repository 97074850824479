import { useState } from "react";
import useT  from '../Traduction'
import { Auth } from 'aws-amplify';
import ButtonLink from '../ButtonLink'

function UpdateFormat({ dataFormat, userGroup, dataProject }) {
  const [formData, setFormData] = useState(dataFormat);
  const t = useT();
  const formatName = dataFormat.name
  const [receivedResponse, setReceivedResponse] = useState(false);
  const [updateSucessullyMsg, setUpdateSuccessfullyMsg] = useState("")
  const [errorMessage, setErrorMessage] = useState("");
  const [inProgress, setInProgress] = useState("");

  if (!formData) { return null }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (userGroup === "super-admin" || userGroup === "admin" || userGroup === "client") {
      setErrorMessage("")
      setInProgress(t("Update_format_in_progress"))
      await FetchApiUpdateFormat(formatName, formData, setErrorMessage, t, dataProject, setReceivedResponse, setInProgress, setUpdateSuccessfullyMsg);
    } else {
      setErrorMessage(t("Required_rights_to_perform_this_action"));
    }
  }

  // Pour mise à jour des clés dans les champs dict
  const handleDictInputChange = (event, parentKey, childKey, key) => {
    // recup l input
    const { name, value } = event.target;

    if (key != undefined) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [key] : {
          ...prevFormData[key],
          [parentKey]: {
            ...prevFormData[key][parentKey],
            [childKey]:value
          }
        }}
      ))
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [parentKey]: {
          ...prevFormData[parentKey],
          [childKey]: {
            ...prevFormData[parentKey][childKey],
            [name]: value
          }
        }
      }));
    }
  };


  return (
    <div className="container-fluid mt-5 d-flex justify-content-center">
      <div className="boxed-create">
        <form onSubmit={handleSubmit}>
          {/* Afficher tous les champs de l'objet avec leurs valeurs respectives */}
          {Object.entries(formData).map(([key, value]) => {
            if (typeof value === 'object' && value !== null) {
              return (
                <div key={key} className="form-group">
                  <label><strong>{t(key)}</strong></label>
                  {Object.entries(value).map(([dictKey, dictValue]) => (
                    typeof dictValue === 'object' && dictValue !== null ? (
                      Object.entries(dictValue).map(([subKey, subValue]) => (
                        <div key={`${dictKey}-${subKey}`} className="form-group m-2">
                          <label htmlFor={`${dictKey}-${subKey}`}><strong>{t(`${dictKey} - ${subKey}`)}</strong></label>
                          <input
                            type="text"
                            className="form-control"
                            id={`${dictKey}-${subKey}`}
                            name={`${subKey}`}
                            value={subValue}
                            onChange={(event) => handleDictInputChange(event, dictKey, subKey, key)}
                          />
                        </div>
                      ))
                    ) : (
                      <div key={`${key}-${dictKey}`} className="form-group m-2">
                        <label htmlFor={`${key}-${dictKey}`}>{t(dictKey)}</label>
                        <input
                          type="text"
                          className="form-control"
                          id={`${key}-${dictKey}`}
                          name={`${dictKey}`}
                          value={dictValue}
                          onChange={(event) => handleDictInputChange(event, key, dictKey)}
                        />
                      </div>
                    )
                  ))}
                </div>
              );
            } else {
              return (
                <div key={key} className="form-group m-2">
                  <label htmlFor={key}>{t(key)}</label>
                  <input
                    type="text"
                    className="form-control"
                    id={key}
                    name={key}
                    value={value}
                    onChange={handleInputChange}
                  />
                </div>
              );
            }
          })}
            <div className="d-flex justify-content-between align-items-start">
              {!receivedResponse && (
              <button type="submit" className="btn btn-primary mt-3">{ t('update') }</button>
              )}
              <ButtonLink link="/tarif" linkText={t('Back')} className="btn btn-primary" />
            </div>
          {inProgress && (
            <div className="alert alert-primary mt-4 fw-bold">{inProgress}</div>
          )}
          {receivedResponse && (
            <div className="alert alert-success mt-4 fw-bold">{updateSucessullyMsg}</div>
          )}
          {errorMessage && (
            <div className="alert alert-warning mt-4 fw-bold">{errorMessage}</div>
          )}
        </form>
      </div>
    </div>
  );
}

async function FetchApiUpdateFormat(formatName, formData, setErrorMessage, t, dataProject, setReceivedResponse, setInProgress, setUpdateSuccessfullyMsg) {

  let url = `${dataProject.startUrl}/update-format`;

  const fieldsToUpdate = {}

  for(const key in formData) {
    if (formData.hasOwnProperty(key)) {
      if (formData[key] !== null && formData[key] !== undefined) {
        fieldsToUpdate[key] = formData[key];
      } else {
        fieldsToUpdate[key] = null 
      }
  }}
  const response = await fetch(url, {
    headers: {
      "Authorization": `BearerCognito ${dataProject.cognitoToken}`,
      "Content-Type": "application/json",

    },
    method: 'POST',
    body: JSON.stringify(fieldsToUpdate)
  })

  let dataResultFormat;

  try {
    const data = await response.json();
  } catch (error) {
    setErrorMessage(t("Unexpected_error"));
  }

  setInProgress("")

  if (!response.ok) {
    if (response.status === 404) {
      setErrorMessage(`${t("The_male")} ${t("format")} ${formatName.toUpperCase()} ${t("does_not_exist")}.`)
    } else if (response.status === 0) {
      setErrorMessage(`${t("Download_failed_due_to_a_connection_problem")}.`);
    } else if (response.status === 500) {
      setErrorMessage(t("Unexpected_error"));
    } else if (response.status === 401) {
      setErrorMessage(t("Unauthorized"));
      console.log(t("Unauthorized"));
      Auth.signOut()
    } else {
      setErrorMessage(t("Unexpected_error"));
    }
  } else {
    setReceivedResponse(true);
    setUpdateSuccessfullyMsg(t("Format updated successfully"))
  }
}



export default UpdateFormat;